<template>
  <b-row>
    <b-col md="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <h3 class="text-danger">
              GROSS: ${{ total ? numberWithCommas(total) : 0 }}
            </h3>
            <div>
              <label
                for="import-csv"
                class="btn btn-success"
              >Import CSV</label>
              <b-form-file
                id="import-csv"
                v-model="file"
                class="d-none"
                @input="uploadCSV"
              />
            </div>
          </div>
        </b-card-body>
        
        <!-- Payment Table -->
        <payment-table
          v-if="items.length > 0"
          :items="items"
        />

        <div
          v-if="items.length == 0"
          class="text-center px-5 py-5"
        >
          There is no content!
        </div>

      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormFile, BCardBody, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'

import * as paymentService from '@/services/payment'
import PaymentTable from './Table.vue'

export default {
  components: {
    BCardCode,
    BCardBody,
    BRow,
    BCol,
    BFormFile,
    PaymentTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      items: [],
      file: null,
      csv: null,
      total: '',
    }
  },

  mounted() {
    this.getPayments()
  },

  methods: {
    /** Get Table Data */
    getPayments() {
      this.$store.commit('set_loading_action', true)
      paymentService.getPayments()
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.items = res.data.payments
          this.total = res.data.total.amount.toFixed(2)
          this.totalRows = this.items.length
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('set_loading_action', false)
        })
    },

    /** Upload CSV */
    uploadCSV(file) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upload it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const fileReader = new FileReader()
          /** read file as string */
          fileReader.readAsBinaryString(file)
          fileReader.onload = e => {
            const data = e.target.result
            // eslint-disable-next-line no-undef
            const workbook = XLSX.read(data, { type: 'binary' })
            const rowObjects = []
            workbook.SheetNames.forEach(sheet => {
              // eslint-disable-next-line no-undef
              const rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
              rowObjects.push(rowObject)
            })
            /** call rearrange function */
            this.importCSV(rowObjects)
          }
        }
      })
    },

    /** Post Upload CSV */
    importCSV(json) {
      this.$store.commit('set_loading_action', true)
      paymentService.importCSV({ payload: json })
        .then(() => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: 'CSV has been uploaded successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.getPayments()
            }
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              console.log('')
            }
          })
        })
    },

    /** format total gross pay */
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';

  .margin-0 {
    margin: 0px;
  }
</style>
